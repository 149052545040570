import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import neb_img from "../assets/MEREB_GTEX_WEB_FILES/Nebyou.png";
import yar_img from "../assets/MEREB_GTEX_WEB_FILES/Yared.png";
import sam_img from "../assets/MEREB_GTEX_WEB_FILES/Samuel.png";
import pocker from "../assets/MEREB_GTEX_WEB_FILES/Poker.jpg";
import nft from "../assets/MEREB_GTEX_WEB_FILES/NFT.jpg";
import logo from "../assets/images/logo-3.png";
import useNavbarEffect from "../hooks/useNavbarEffect";
import bg1 from "../assets/MEREB_GTEX_WEB_FILES/Background_Slider.jpg";
import bg2 from "../assets/MEREB_GTEX_WEB_FILES/Background_midsection.jpg";
import team from "../assets/MEREB_GTEX_WEB_FILES/Slider_GTEX_Image-2.png";
import lightIcon from "../assets/MEREB_GTEX_WEB_FILES/Icons/Inovvative.svg";
import topTier from "../assets/MEREB_GTEX_WEB_FILES/Icons/talent.svg";
import client from "../assets/MEREB_GTEX_WEB_FILES/Icons/Client_Centric.svg";
export default function GTXPage() {
  const [response, setResponse] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  function handleSubscription() {
    setResponse("");
    setError("");
    if (!handleValidation()) return;
    setSubmitted(true);

    emailjs
      .send(
        REACT_APP_SERVICE_ID,
        REACT_APP_TEMPLATE_ID,
        {
          from_name: "GITEX & North Star Attendee: " + email,
          replay_to: email,
          message: "Interested in Mereb Technologies: " + email,
        },
        REACT_APP_USER_ID
      )
      .then(
        () => {
          setResponse("Successfully submitted!");
          setSubmitted(false);
          setTimeout(() => {
            setResponse("");
          }, 5000);
        },
        (error) => {
          setError("Error while submitting!");
          setSubmitted(false);
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      );
  }

  function handleValidation() {
    if (!email) {
      setError("Email is required!");
      return false;
    }
    if (!isValidEmail(email)) {
      setError("Invalid Email!");
      return false;
    }
    return true;
  }

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_USER_ID } =
    process.env;

  useNavbarEffect(true, false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="pt-24 bg-gradient-to-r from-blue-500 to-blue-600
    

    "
      style={{
        backgroundImage: `url(${bg1})`,
      }}
    >
      {/* Intro Section */}
      <section className="text-white py-16 px-8 rounded-lg mb-16 flex flex-col lg:flex-row items-center lg:justify-between max-w-5xl mx-auto">
        <div className="text-center lg:text-left lg:max-w-lg">
          <h1 className="text-5xl font-black">Let's Connect</h1>
          <p className="text-xl leading-relaxed mb-1">
            Shaping the Future of Software and Digital Transformation Together!
          </p>
          <p className="text-md mb-6 text-blue-200">
            Mereb Technologies is attending GITEX & North Star Events. Explore
            how our expertise in software development, AI, and talent
            outsourcing can accelerate your business growth.
          </p>
          <div className="flex justify-center lg:justify-start w-fit mx-auto lg:mx-0 rounded-full overflow-hidden border-2 border-blue-500 transition duration-300 focus:border-blue-400 bg-transparent">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email Address"
              className="px-4 py-2 w-64 rounded-l-full text-white bg-transparent outline-none"
            />
            <button
              onClick={handleSubscription}
              className="bg-gradient-to-l from-blue-400 to-blue-500 hover:bg-blue-400 text-white font-bold px-6 py-2 rounded-full transition duration-300"
            >
              {submitted ? "Submitting..." : "Subscribe"}
            </button>
          </div>
          {error && (
            <span class=" ml-4 mt-2 bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
              {error}
            </span>
          )}
          {response && (
            <span class=" ml-4 mt-2 bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
              {response}
            </span>
          )}
        </div>
        <div className="mt-8 lg:mt-0  w-full lg:w-auto flex justify-center lg:justify-end">
          <img
            src={team}
            alt="GITEX Event"
            className="relative rounded-lg shadow-xl w-full lg:w-96 object-cover border-3 border-blue-500 border-opacity-50"
          />
        </div>
      </section>

      {/* Exclusive Offer Section */}
      <section className="bg-gray-300 p-8 shadow-lg   w-[100%] mx-auto rounded-tr-[4rem] rounded-tl-[4rem]">
        <div className="bg-white p-8 shadow-lg rounded-lg mb-16 max-w-5xl mx-auto mt-16">
          <h3
            className="text-2xl font-bold text-center mb-6 rounded-lg
          text-primary
          "
          >
            Exclusive Offer for GITEX & North Star Attendees
          </h3>
          <p className="text-lg text-gray-700 text-center mb-8 max-w-3xl mx-auto">
            As a special opportunity for GITEX and North Star attendees, Mereb
            Technologies is offering two exclusive deals to help you access
            world-class solutions with ease
          </p>
          <div className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0 justify-center">
            {[
              {
                title: "Reduced Rates on Talent Outsourcing",
                description:
                  "Access our highly skilled IT professionals at a special event-only 10% reduced rate. Scale your team without the hassle and cost of recruitment.",
                buttonText: "Claim Your Offer",
                link: "https://appt.link/meet-with-kibur-kibret-luZnTc5x/Talent-Leasing-Service",
              },
              {
                title: "Complimentary Consultation",
                description:
                  "Get a complimentary consultation with our experts to discuss how our solutions can solve your unique business challenges.",
                buttonText: "Book a Meeting Now",
                link: "https://appt.link/meet-with-kibur-kibret-luZnTc5x/expert-consultation",
              },
            ].map((offer, index) => (
              <div
                key={index}
                className="flex-1 bg-gradient-to-br from-blue-50 to-blue-100 p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 transform hover:-translate-y-2 text-center"
              >
                <div className="text-blue-700 text-3xl mb-4 flex justify-center items-center">
                  {/* Placeholder for an icon, you can replace with an actual icon */}
                  {index === 0 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 text-blue-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 14l2 2 4-4m5 4v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6m16-8h-4a2 2 0 00-2-2h-4a2 2 0 00-2 2H5l-2 2v2h20V6l-2-2z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 text-blue-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M16 12v4m0 0v4m0-4h4m-4 0H8m6-10a2 2 0 012 2v12a2 2 0 01-2 2m-4 0H8a2 2 0 01-2-2V6a2 2 0 012-2m4 0h4"
                      />
                    </svg>
                  )}
                </div>
                <h4 className="text-xl font-semibold text-blue-700 mb-2">
                  {offer.title}
                </h4>
                <p className="mt-2 text-gray-600">{offer.description}</p>
                <button className="mt-6 px-6 py-2 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-300">
                  <a href={offer.link} target="_black">
                    {offer.buttonText}
                  </a>
                </button>
              </div>
            ))}
          </div>
        </div>
        {/* Who We Are Section */}
        <div className="text-gray-700 max-w-4xl mx-auto text-left mb-8">
          <p className="text-lg mb-8">
            We are a forward-thinking IT company specializing in innovative
            software development, game development, and IT talent outsourcing.
            We provide custom solutions that help businesses in healthcare,
            finance, and retail thrive in the digital world.
          </p>
          <div className="flex flex-row gap-6 flex-wrap md:justify-around">
            {[
              {
                Text: "Innovative Custom Solutions",
                Icon: (
                  <img
                    src={lightIcon}
                    width={25}
                    alt="Innovative Custom Solutions"
                  />
                ),
              },
              {
                Text: "Top-Tier IT Talent",
                Icon: (
                  <img
                    src={topTier}
                    width={26}
                    alt="Innovative Custom Solutions"
                  />
                ),
              },
              {
                Text: "Client-Centric Partnerships",
                Icon: (
                  <img
                    src={client}
                    width={28}
                    alt="Innovative Custom Solutions"
                  />
                ),
              },
            ].map((item, index) => (
              <div key={index} className="flex items-center space-x-2">
                {item.Icon}
                <p className="text-black font-bold text-lg ">{item.Text}</p>
              </div>
            ))}
          </div>
        </div>
        {/* Why Choose Us Section */}
        <div className="p-8 text-center rounded-lg mt-16 mb-16 max-w-5xl mx-auto">
          <h3 className="text-3xl font-extrabold text-center mb-8 text-primary">
            What We Offer
          </h3>
          <p className="text-lg text-gray-700 mb-8 max-w-3xl mx-auto">
            Unlock the full potential of your business by partnering with Mereb
            Technologies. Experience tailored solutions and strategic advantages
            that drive innovation and growth.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12">
            {[
              {
                title: "Software Development",
                description: "Custom, scalable solutions across industries.",
                icon: (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                    />
                  </svg>
                ),
              },
              {
                title: "Top Talent Access",
                description:
                  "Scale your team with top IT professionals at reduced costs.",
                icon: (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                ),
              },
              {
                title: "Custom Solutions",
                description:
                  "Tailored software and AI-driven innovations for your business needs.",
                icon: (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                    />
                  </svg>
                ),
              },
              {
                title: "Scalable Outsourcing",
                description:
                  "Easily expand or contract your team based on project demands.",
                icon: (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                ),
              },
              {
                title: "Accelerate Growth",
                description:
                  "Implement AI, IoT, and cloud technologies to stay ahead.",
                icon: (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                ),
              },
              {
                title: "Security & Compliance",
                description:
                  "Industry-standard data protection and regulatory compliance.",
                icon: (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                ),
              },
            ].map((service, index) => (
              <div
                key={index}
                className="mt-6 pt-8 pb-6 px-6 rounded-3xl bg-gray-100 shadow-sm flex flex-col items-center relative text-center "
              >
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="bg-white p-3 rounded-full shadow-md">
                    <div className="text-blue-600">{service.icon}</div>
                  </div>
                </div>
                <h4 className="font-black text-xl mb-2 text-gray-800 mt-4">
                  {service.title}
                </h4>
                <p className="text-gray-600 text-sm">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Meet the Team Attending GITEX & North Star Section */}
      <div
        className="py-12"
        style={{
          backgroundImage: `url(${bg2})`,
        }}
      >
        <h3 className="text-3xl font-extrabold text-center mb-8 text-white">
          Meet the Team Attending GITEX & North Star
        </h3>
        <p className="text-center mt-8 max-w-3xl mx-auto text-white">
          We’re attending GITEX and North Star to connect with industry leaders,
          explore potential collaborations, and share insights into the future
          of digital transformation. Let’s explore how Mereb can contribute to
          your next project.
        </p>
        <div className="flex justify-center space-x-8 pt-7">
          <div className="text-center">
            <img
              src={neb_img}
              alt="Nebyu Zekarias - CEO"
              className="w-40 h-45 object-fit mx-auto mb-4"
            />
            <h4 className="font-semibold text-white">Nebyu Zekarias</h4>
            <p className="text-white">CEO</p>
          </div>
          <div className="text-center">
            <img
              src={yar_img}
              alt="Yared Taddese - COO"
              className="w-40 h-45 object-fit  mx-auto mb-4"
            />
            <h4 className="font-semibold text-white">Yared Taddese</h4>
            <p className="text-white">COO</p>
          </div>
          <div className="text-center">
            <img
              src={sam_img}
              alt="Samuel Mussie - CTO"
              className="w-40 h-45 object-fit  mx-auto mb-4"
            />
            <h4 className="font-semibold text-white">Samuel Mussie</h4>
            <p className="text-white">CTO</p>
          </div>
        </div>
        <div className="text-center mt-8">
          <button
            className="bg-gradient-to-l from-blue-400 to-blue-500
          mt-2 px-7 py-1  text-white rounded-lg hover:bg-blue-500 "
          >
            <a className="nostyle" target="_blank" href="https://wa.me/+251916144128">Book a Meeting</a>
          </button>
        </div>
      </div>

      {/* Our Works Section */}
      <section className="bg-gray-300 p-8 shadow-lg  mb-16 w-[100%] rounded-bl-[4rem] rounded-br-[4rem]">
        <p className="text-lg text-gray-700 text-left mb-8 max-w-3xl mx-auto mt-16">
          Over the past four years, we have successfully developed and
          maintained a poker gaming platform for a client in Nevada.
        </p>
        {/* card  */}
        <div
          className=" rounded-xl overflow-hidden  justify-center
        flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0"
        >
          {[
            {
              title: "Real Gaming Poker USA",
              description:
                "Developed a custom poker platform with real-time gaming features.",
              buttonText: "View Project",
              img: pocker,
              link: "/Insights/poker_optimization",
            },
            {
              title: "Prism NFT MarketPlace",
              description:
                "Built a secure NFT marketplace for digital art and collectibles.",
              buttonText: "View Project",
              img: nft,
              link: "/portfolio",
            },
          ].map((project, index) => (
            <div class="max-w-[350px] bg-white rounded-xl overflow-hidden shadow-md">
              <img
                class="w-full h-48 object-cover"
                src={project.img}
                alt="Real Gaming Poker USA"
              />
              <div class="p-6 ">
                <h2 class="text-blue-600 font-bold text-xl mb-2">
                  {project.title}
                </h2>
                <p class="text-gray-700 text-base mb-4">
                  {project.description}
                </p>
                <a
                  href={project.link}
                  class="text-blue-600 font-bold hover:underline"
                >
                  Read More
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Key Achievements Section */}
        {/* Key Achievements Section */}
        <div className="bg-blue-800 text-white mt-8 pb-16 pt-5 px-8 rounded-[4rem] shadow-lg mb-16 max-w-5xl mx-auto ">
          <h3 className="text-3xl font-[990] text-center mb-8">
            Key Achievements
          </h3>
          <div className="flex flex-col md:flex-row items-center justify-center md:space-x-6">
            {/* Left-Aligned Content */}
            <div
              className="  w-[300px] bg-gradient-to-br from-white to-blue-200 text-blue-900 p-6 rounded-[2rem] shadow-md transform transition duration-300 hover:scale-105 mb-6 md:mb-0
            flex flex-col items-start justify-center
            "
            >
              <p className="text-4xl font-extrabold mb-2">$1,251,420</p>
              <p className="text-lg font-semibold">
                Saved for our client in development costs
              </p>
            </div>

            {/* Right-Aligned Content */}
            <div className=" md:w-1/2 grid grid-cols-1 gap-6">
              <div
                className="bg-gradient-to-br from-white to-blue-200 text-blue-900 p-6 rounded-[2rem] shadow-md transform transition duration-300 hover:scale-105
              flex flex-row items-center justify-center gap-2
              "
              >
                <p className="text-4xl font-extrabold mb-2 whitespace-nowrap">
                  1 Year
                </p>
                <p className="text-lg font-semibold">
                  Reduced time to launch by scaling their team and resources
                </p>
              </div>
              <div className="bg-gradient-to-br from-white to-blue-200 text-blue-900 p-6 rounded-[2rem] shadow-md transform transition duration-300 hover:scale-105 flex flex-row items-center justify-center gap-1">
                <p className="text-lg font-semibold text-right">
                  Minimized delays on Talent Acquisition
                </p>
                <p className="text-4xl font-extrabold mb-2 whitespace-nowrap">
                  30 Days
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-8 mb-8">
            <button className="mt-4 px-6 py-2 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-300">
              <a href="/portfolio">See More Success Stories</a>
            </button>
        </div>
      </section>

      {/* Let’s Talk Tech Section */}
      <section className="text-white py-12 px-8 max-w-6xl mx-auto flex flex-col space-y-8">
        <div className="flex flex-col md:flex-row md:justify-between items-center md:items-start space-y-8 md:space-y-0 text-left">
          <div className="flex-1 text-center md:text-left">
            <h3 className="text-4xl font-extrabold mb-4">Let’s Talk Tech!</h3>
            <p className="text-lg mb-4">
              If you’re interested in learning more about how Mereb can help
              your business evolve through innovative software solutions and
              world-class talent outsourcing, we’d love to connect.
            </p>
          </div>

          {/* Vertical Divider for Larger Screens */}
          <div className="hidden md:block h-32 w-px bg-white mx-8"></div>

          {/* Contact Information */}
          <div className="flex flex-1 flex-col md:flex-row md:space-x-12 items-center md:items-start text-center md:text-left space-y-8 md:space-y-0">
            <div>
              <p className="font-semibold">Sheridan, Wyoming, USA</p>
              <p>30 N Gould St, Ste R, Sheridan, WY 82801</p>
              <p>
                Phone:{" "}
                <a href="tel:+13072069767" className="hover:underline">
                  +1(307)-206-9767
                </a>
              </p>
            </div>
            <div>
              <p className="font-semibold">Addis Ababa, Ethiopia</p>
              <p>Bole, Airport Road</p>
              <p>
                Phone:{" "}
                <a href="tel:+251916144128" className="hover:underline">
                  +251916144128
                </a>{" "}
                /{" "}
                <a href="tel:+251939346647" className="hover:underline">
                  +251939346647
                </a>
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@mereb.tech" className="hover:underline">
                  info@mereb.tech
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mt-16 text-center md:text-left space-y-4 md:space-y-0">
          <p className="text-sm">All rights reserved (2024)</p>
          <img
            src={logo}
            alt="Mereb Technologies Logo"
            className="w-32 md:w-52 mx-auto md:mx-0"
          />
        </div>
      </section>
    </div>
  );
}
