import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Navbar, Footer } from "./components";
import { useCookies } from "react-cookie";
import "./index.css";
import {
  AboutPage,
  ContactPage,
  // LandingPage,
  NotFoundPage,
  PortfolioPage,
  PrivacyPage,
  BlogsPage,
  BlogDetail,
  OurTeam,
  JobApplicationPage,
  DetailedJobView,
  GTXPage,
} from "./pages";

const useCookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookie"]);
  const hasConsented = !!cookies.cookie;

  const onAccept = () => {
    setCookie("cookie", "cookie", { path: "/" });
  };

  return { hasConsented, onAccept };
};

function App() {
  const { hasConsented, onAccept } = useCookieConsent();
  const location = useLocation();

  const isHomePage = location.pathname === "/";

  return (
    <div>
      <Navbar />
      <div className="min-h-screen">
        <Routes>
          <Route path="/" element={<GTXPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route
            path="author/merebtech"
            element={<Navigate replace to="/" />}
          />
          <Route path="our-team" element={<OurTeam />} />
          <Route path="insights" element={<BlogsPage />} />
          <Route path="/insights/:id" element={<BlogDetail />} />
          <Route path="jobs" element={<JobApplicationPage />} />
          <Route path="/jobs/:id" element={<DetailedJobView />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      {!hasConsented && (
        <div className="animate-cookie ">
          <div className="CookieConsent bg-primary">
            <div className="customCookie container mx-auto flex flex-col justify-center items-center py-4 px-4 md:flex-row">
              <div>
                <p className="px-4">
                  Mereb Technologies uses cookies to provide enhanced website
                  functionality and experience. By using our website, you agree
                  to
                  <a
                    className="mx-2 text-white font-bold underline hover:text-gray-400 transition-colors cursor-default text-center md:text-left w-full"
                    href="/privacy"
                  >
                    our privacy notice.
                  </a>
                </p>
              </div>
              <div>
                <button
                  id="rcc-confirm-button"
                  aria-label="Accept cookies"
                  type="button"
                  onClick={onAccept}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isHomePage && <Footer />}
    </div>
  );
}

export default App;
